<script>
    import {apiRequest} from '../libs/Request.svelte';
    import {dictionary, locale, _} from "svelte-i18n";

    export let language;

    dictionary.set({
        ru: {
            'title': 'Восстановление пароля',
            'description': 'Сбросить пароль и выслать новый на указанный адрес',
            'emailPlaceholder': 'Введите e-mail',
            'emailRequiredError': 'Email обязателен для ввода',
            'submitButtonLabel': 'Сбросить пароль',
            'successMessage': 'Письмо для подтверждения было отправлено на ваш ящик.',
        },
        en: {
            'title': 'Password recovery',
            'description': 'Reset your current password.',
            'emailPlaceholder': 'Enter Email',
            'emailRequiredError': 'Email required!',
            'submitButtonLabel': 'Reset password',
            'successMessage': 'Confirmation message was sent to your email.',
        },
    });

    let logoSrc = null;
    switch (language) {
        case 'ru-RU':
            locale.set('ru');
            logoSrc = '/images/zencpa-logo_black.svg';
            break;
        case 'en-US':
        default:
            locale.set('en');
            logoSrc = '/images/fruitcpa-logo_black.svg';
            break;
    }

    let emailError = null;
    let email = null;

    function resetErrors() {
        errorMessage = null;
        emailError = null;
    }

    const emailRequiredError = $_('emailRequiredError');
    function checkData() {
        if (!email) {
            emailError = emailRequiredError;
            return false;
        }

        return true;
    }

    function prepareData() {
        return {
            email: email,
        }
    }

    let isSuccessVisible = false;
    let hasError = false;
    let errorMessage = null;
    async function handleSubmit(e) {
        resetErrors();
        if (!checkData()) {
            console.log(prepareData());
            return;
        }

        const response = await apiRequest("post", '/auth/ajax-reset-password', JSON.stringify(prepareData()), {
            'Content-Type': 'application/json',
        })
        isSuccessVisible = (response.status === 'ok');
        if (!isSuccessVisible) {
            errorMessage = response.message;
            return;
        }

        setTimeout(function(){
            isSuccessVisible = false;
        }, 10000);
    }


</script>

<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body pb-5 pt-4">

        <div class="alert alert-success {isSuccessVisible ? '' : 'd-none'}">
            {$_('successMessage')}
        </div>

        <div class="row">
            <div class="col-12 offset-0 col-lg-6 offset-lg-3 mb-3">
                <div class="img" style="text-align: center;"><img src={logoSrc} alt=""></div>
            </div>

            <div class="col-12 text-center mb-5">
                <div class="title-wrap h2">{$_('title')}</div>
                <div class="description-wrap">{$_('description')}</div>
            </div>

            <div class="col-12 offset-0 col-lg-6 offset-lg-3">
                <div>
                    <span class="{errorMessage ? '' : 'd-none'}" style="color: red;">
                        <p class="help-block help-block-error">{errorMessage}</p>
                    </span>
                </div>
                <form id="reset-form" class="login-form" on:submit|preventDefault={handleSubmit}>
                    <div class="modal-fields">
                        <span class="fz-10">{$_('email')}</span>
                        <div class="form-group field-zencpaloginform-mail required">
                            <input type="email"
                                   id="zencparesetform-mail"
                                   class="form-control py-3 mb-3"
                                   bind:value={email}
                                   placeholder="{$_('emailPlaceholder')}"
                                   aria-required="true">
                            <span class="{emailError ? '' : 'd-none'}" style="color: red;">
                                <p class="help-block help-block-error">{emailError}</p>
                            </span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center mt-4 mb-4 justify-content-between">
                        <button type="submit"
                                class="btn.bak border-rounded btn-blue fw-bolder fw-6 py-3 registration-send"
                                style="max-width: 190px;"
                                aria-label="button">
                            <span class="white">{$_('submitButtonLabel')}</span>
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>

<style>
    .border-rounded {
        border-radius: 12px!important;
    }
</style>
