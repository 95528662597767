<script>
    import {dictionary, locale, _} from "svelte-i18n";
    import {fly, fade } from 'svelte/transition';
    import {apiRequest} from '../libs/Request.svelte';

    export let language;
    export let name;
    export let surname;
    export let email;
    export let username;
    let currentPassword = null;
    let newPassword = null;
    let confirmPassword = null;

    let hasError = false;
    let isSuccessVisible = false;
    let submitted = false;

    dictionary.set({
        ru: {
            "profile": {
                "title": "Информация профиля",
                "name": "Имя",
                "surname": "Фамилия",
                "email": "Почта",
                "login": "Логин",
                "errMessage": "Все поля обязательны для заполнения",
                "save": "Сохранить изменения",
                "successUpdate": "Данные обновлены",
            },
            "password": {
                "currentPassword": "Текущий пароль",
                "newPassword": "Придумайте пароль",
                "confirmPassword": "Подтвердите пароль",
                "settings": "Изменение пароля",
                "confirmErrorMsg": "Пароли не совпадают",
            }
        },
        en: {
            "profile": {
                "title": "Profile Info",
                "name": "Name",
                "surname": "Surname",
                "email": "Email",
                "login": "Login",
                "errMessage": "Все поля обязательны для заполнения",
                "save": "Save changes",
                "successUpdate": "Data saved successfully",
            },
            "password": {
                "currentPassword": "Current password",
                "newPassword": "Enter password",
                "confirmPassword": "Confirm password",
                "settings": "Change password",
                "confirmErrorMsg": "Passwords do not match",
            }
        },
    });

    switch (language) {
        case 'ru-RU':
            locale.set('ru');
            break;
        case 'en-US':
        default:
            locale.set('en');
            break;
    }

    let errors = {}
    let nameIsValid = true;
    let nameError = '';
    let surnameIsValid = true;
    let surnameError = '';
    let emailIsValid = true;
    let emailError = '';
    let loginIsValid = true;
    let loginError = '';
    let passwordIsValid = true;
    let passwordError = '';
    let newPasswordIsValid = true;
    let newPasswordError = '';
    let confirmPasswordIsValid = true;
    let confirmPasswordError = '';

    const errMessage = $_('profile.errMessage');
    const confirmPasswordErrorMsg = $_('password.confirmErrorMsg');

    function checkNewPassword() {
        const result = (newPassword === confirmPassword);
        if (!result) {
            hasError = true;
            confirmPasswordIsValid = false;
            confirmPasswordError = confirmPasswordErrorMsg;
            return false;
        }

        return true;
    }

    function resetErrors() {
        errors = {};
        hasError = false;
        nameIsValid = true;
        nameError = '';
        surnameIsValid = true;
        surnameError = '';
        emailIsValid = true;
        emailError = '';
        loginIsValid = true;
        loginError = '';
        passwordIsValid = true;
        passwordError = '';
        newPasswordIsValid = true;
        newPasswordError = '';
        confirmPasswordIsValid = true;
        confirmPasswordError = '';
        errorMessage = '';
    }

    function prepareSettings() {
        return {
            name: name,
            surname: surname,
            email: email,
            username: username,
            currentPassword: currentPassword,
            newPassword: newPassword,
        };
    }

    let errorMessage = '';
    async function handleSubmit(e) {
        resetErrors();
        if (!checkNewPassword()) {
            return;
        }

        const response = await apiRequest("post", '/api/save-profile', JSON.stringify(prepareSettings()), {
            'Content-Type': 'application/json',
        })
        isSuccessVisible = (response.status === 'ok');
        hasError = response.error ? true : false;
        errorMessage = response.error ? response.error : '';
        if (response.status === 'error') {
            errors = response.errors;
        }

        setTimeout(function(){
            isSuccessVisible = false;
        }, 4000);
    }

</script>

    <div class="error-alert {hasError ? '' : 'hidden'}">{errMessage}</div>
    <div class="error-alert {isSuccessVisible ? '' : 'hidden'}" transition:fade={{duration:150}}>
        {$_('profile.successUpdate')}
    </div>

    <form class="profile-settings__form" on:submit|preventDefault={handleSubmit}>
        <fieldset class="profile-settings__fieldset">
            <legend class="profile-settings__fieldset-title">{$_('profile.title')}</legend>
            <div class="profile-settings__form-group">
                <label class="{!errors.name ? 'valid' : 'error'}">
                    <span class="profile-settings__label">{$_('profile.name')}</span>
                    <input class="input" type="text" placeholder="" bind:value={name}>
                </label>
                <span class="error-msg">{errors.name ? errors.surname : ''}</span>
            </div>
            <div class="profile-settings__form-group">
                <label class="{!errors.surname ? 'valid' : 'error'}">
                    <span class="profile-settings__label">{$_('profile.surname')}</span>
                    <input class="input" type="text" placeholder="" bind:value={surname}>
                </label>
                <span class="error-msg">{errors.surname ? errors.surname : ''}</span>
            </div>
            <div class="profile-settings__form-group">
                <label class="{!errors.email ? 'valid' : 'error'}">
                    <span class="profile-settings__label">{$_('profile.email')}</span>
                    <input class="input" type="text" placeholder="example@mail.com" bind:value={email}>
                </label>
                <span class="error-msg">{errors.email ? errors.email : ''}</span>
            </div>
            <div class="profile-settings__form-group">
                <label class="{!errors.login ? 'valid' : 'error'}">
                    <span class="profile-settings__label">{$_('profile.login')}</span>
                    <input class="input" type="text" placeholder="" bind:value={username}>
                </label>
                <span class="error-msg">{errors.login ? errors.login : ''}</span>
            </div>
        </fieldset>
        <fieldset class="profile-settings__fieldset">
            <legend class="profile-settings__fieldset-title">{$_('password.settings')}</legend>
            <div class="profile-settings__form-group">
                <label class="label_password {!errors.currentPassword ? 'valid' : 'error'}">
                    <span class="modal__show-password">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C9.30747 6 9.60413 6.04625 9.88341 6.13218C9.36251 6.36736 9 6.89135 9 7.5C9 8.32843 9.67157 9 10.5 9C11.1087 9 11.6326 8.63749 11.8678 8.11659C11.9537 8.39587 12 8.69253 12 9Z" fill="#686E78"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 14C5.53498 14 2.94819 11.4646 1.74589 10.0047C1.25852 9.41293 1.25852 8.58707 1.7459 7.99527C2.94819 6.53536 5.53498 4 9 4C12.465 4 15.0518 6.53536 16.2541 7.99527C16.7415 8.58707 16.7415 9.41293 16.2541 10.0047C15.0518 11.4646 12.465 14 9 14ZM8.47253 12.9771C3.98843 12.5888 3.98843 5.41115 8.47252 5.02285C8.64677 5.00776 8.82263 5 9 5C9.17736 5 9.35322 5.00776 9.52745 5.02285C14.0116 5.41113 14.0116 12.5889 9.52745 12.9771C9.35322 12.9922 9.17736 13 9 13C8.82263 13 8.64677 12.9922 8.47253 12.9771Z" fill="#686E78"></path>
                        </svg>
                    </span>
                    <input class="input" type="password" placeholder="{$_('password.currentPassword')}" bind:value={currentPassword}>
                </label>
                <span class="error-msg">{errors.currentPassword ? errors.currentPassword : ''}</span>
            </div>
            <div class="profile-settings__form-group">
                <label class="label_password {!errors.newPassword ? 'valid' : 'error'}">
                    <span class="modal__show-password">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C9.30747 6 9.60413 6.04625 9.88341 6.13218C9.36251 6.36736 9 6.89135 9 7.5C9 8.32843 9.67157 9 10.5 9C11.1087 9 11.6326 8.63749 11.8678 8.11659C11.9537 8.39587 12 8.69253 12 9Z" fill="#686E78"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 14C5.53498 14 2.94819 11.4646 1.74589 10.0047C1.25852 9.41293 1.25852 8.58707 1.7459 7.99527C2.94819 6.53536 5.53498 4 9 4C12.465 4 15.0518 6.53536 16.2541 7.99527C16.7415 8.58707 16.7415 9.41293 16.2541 10.0047C15.0518 11.4646 12.465 14 9 14ZM8.47253 12.9771C3.98843 12.5888 3.98843 5.41115 8.47252 5.02285C8.64677 5.00776 8.82263 5 9 5C9.17736 5 9.35322 5.00776 9.52745 5.02285C14.0116 5.41113 14.0116 12.5889 9.52745 12.9771C9.35322 12.9922 9.17736 13 9 13C8.82263 13 8.64677 12.9922 8.47253 12.9771Z" fill="#686E78"></path>
                        </svg>
                    </span>
                    <input class="input" type="password" placeholder="{$_('password.newPassword')}" bind:value={newPassword}>
                </label>
                <span class="error-msg">{errors.newPassword ? errors.newPassword : ''}</span>
            </div>
            <div class="profile-settings__form-group">
                <label class="label_password  {!errors.confirmPassword ? 'valid' : 'error'}">
                    <span class="modal__show-password">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C9.30747 6 9.60413 6.04625 9.88341 6.13218C9.36251 6.36736 9 6.89135 9 7.5C9 8.32843 9.67157 9 10.5 9C11.1087 9 11.6326 8.63749 11.8678 8.11659C11.9537 8.39587 12 8.69253 12 9Z" fill="#686E78"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 14C5.53498 14 2.94819 11.4646 1.74589 10.0047C1.25852 9.41293 1.25852 8.58707 1.7459 7.99527C2.94819 6.53536 5.53498 4 9 4C12.465 4 15.0518 6.53536 16.2541 7.99527C16.7415 8.58707 16.7415 9.41293 16.2541 10.0047C15.0518 11.4646 12.465 14 9 14ZM8.47253 12.9771C3.98843 12.5888 3.98843 5.41115 8.47252 5.02285C8.64677 5.00776 8.82263 5 9 5C9.17736 5 9.35322 5.00776 9.52745 5.02285C14.0116 5.41113 14.0116 12.5889 9.52745 12.9771C9.35322 12.9922 9.17736 13 9 13C8.82263 13 8.64677 12.9922 8.47253 12.9771Z" fill="#686E78"></path>
                        </svg>
                    </span>
                    <input class="input" type="password" placeholder="{$_('password.confirmPassword')}" bind:value={confirmPassword}>
                </label>
                <span class="error-msg">{errors.confirmPassword ? errors.confirmPassword : ''}</span>
            </div>
        </fieldset>
        <div class="profile-settings__button-group">
            <button class="btn btn_primary" type="submit">{$_('profile.save')}</button>
        </div>
    </form>
