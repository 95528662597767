<script>
    import {dictionary, locale, _} from "svelte-i18n";
    import {fly, fade } from 'svelte/transition';
    import {apiRequest} from '../libs/Request.svelte';

    export let language;
    export let sourcesList;
    let hasError = false;
    let isSuccessVisible = false;
    let submitted = false;
    let isSaveDisabled = false;

    dictionary.set({
        ru: {
            "trafficTabTitle": "Используемые площадки",
            "trafficTabDescription": "Пожалуйста, перечислите все используемые вами площадки, откуда поступает трафик.",
            "trafficSourceSite": "Площадка",
            "trafficSourceUrl": "Ссылка",
            "trafficSourceDescription": "Описание",
            "addTrafficSourceSite": "+ Добавить площадку",
            "saveSettings": "Сохранить изменения",
            "errorOccured": "Возникла ошибка...",
            "successUpdate": "Данные успешно сохранены!",
        },
        en: {
            "trafficTabTitle": "Sources in use",
            "trafficTabDescription": "Please list all the sources of traffic that you use",
            "trafficSourceSite": "Source",
            "trafficSourceUrl": "Link",
            "trafficSourceDescription": "Description",
            "addTrafficSourceSite": "+ Add a source",
            "saveSettings": "Save changes",
            "errorOccured": "That's an error",
            "successUpdate": "Data saved successfully!",
        },
    });

    switch (language) {
        case 'ru-RU':
            locale.set('ru');
            break;
        case 'en-US':
        default:
            locale.set('en');
            break;
    }

    function prepareSourcesList() {
        const result = [];
        for (let i in sourcesList) {
            const source = sourcesList[i];
            if (!source.url) continue;
            result.push(source);
        }

        sourcesList = result;
        return result;
    }

    async function handleSubmit(e) {
        const response = await apiRequest("post", '/api/save-traffic-sources', JSON.stringify(prepareSourcesList()), {
            'Content-Type': 'application/json',
        })
        isSuccessVisible = (response.status === 'ok');

        setTimeout(function(){
            isSuccessVisible = false;
        }, 4000);
    }

    function addSource(event, url = null, description = null, urlError = null, descriptionError = null) {
        sourcesList.push({
            url: url,
            description: description,
            urlError: urlError,
            descriptionError: descriptionError
        });
        sourcesList = sourcesList;
    }

</script>

{#if hasError == true}
    <div class="error-alert">{$_('errorOccured')}</div>
{:else}
    {#if isSuccessVisible}
        <div class="error-alert" transition:fade={{duration:150}}>
            {$_('successUpdate')}
        </div>
    {/if}
{/if}

<form class="profile-settings__form" on:submit|preventDefault={handleSubmit}>
    <h3 class="profile-settings__title">{$_('trafficTabTitle')}</h3>
    <p class="profile-settings__subtitle">{$_('trafficTabDescription')}</p>
    {#each sourcesList as source, i}
        <fieldset class="profile-settings__fieldset profile-settings__fieldset_traffic">
            <legend class="profile-settings__group-title">{$_('trafficSourceSite')} {i+1}
                <div class="profile-settings__form-group">
                    <label class="{source.urlError ? 'error' : ''}">
                        <span class="profile-settings__label profile-settings__label_traffic">
                            {$_('trafficSourceUrl')}
                        </span>
                        <input class="input" type="text" placeholder="https://example.com" bind:value={source.url}>
                    </label>
                    <span class="error-msg">
                        {source.urlError}
                    </span>
                </div>
                <div class="profile-settings__form-group">
                    <label class="{source.descriptionError ? 'error' : ''}">
                        <span class="profile-settings__label profile-settings__label_traffic">
                        {$_('trafficSourceDescription')}
                    </span>
                        <input class="input" type="text" placeholder="My public blog" bind:value={source.description}>
                    </label>
                    <span class="error-msg">
                        {source.descriptionError}
                    </span>
                </div>
            </legend>
        </fieldset>
    {/each}

    <div class="profile-settings__button-group">
        <button class="btn btn_primary"
                type="button"
                on:click|preventDefault={addSource}
        >
            {$_('addTrafficSourceSite')}
        </button>
        <button class="btn btn_primary" type="submit" disabled:isSaveDisabled>
            {$_('saveSettings')}
        </button>
    </div>
</form>
