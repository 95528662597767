import Profile from './profile/Profile.svelte';
import Contacts from './profile/Contacts.svelte';
import Traffic from './profile/Traffic.svelte';
import WithdrawOrderForm from "./modals/WithdrawOrderForm.svelte";
import RegistrationForm from "./modals/RegistrationForm.svelte";
import LoginForm from "./modals/LoginForm.svelte";
import ResetPasswordForm from "./modals/ResetPasswordForm.svelte";
import SaveNewPassword from "./profile/SaveNewPassword.svelte";


function createProfileTab() {
	return new Profile({
		target: document.getElementById('profile-settings'),
		props: {
			language: window.language ? window.language : 'en-US',
			name: window.name ? window.name : null,
			surname: window.surname ? window.surname : null,
			email: window.email ? window.email : null,
			username: window.username ? window.username : null,
		}
	});
}

const paymentSystems = [];
switch (window.language) {
	case 'ru-RU':
		paymentSystems.push({
			title: 'YooKassa',
			description: '',
		});
		break;
	case 'en-US':
	default:
		paymentSystems.push({
			title: 'Wise wallet',
			description: '',
		});
		paymentSystems.push({
			title: 'Bank transfer',
			description: '',
		});
		break;
}

function createContactsTab() {
	return new Contacts({
		target: document.getElementById('profile-contacts'),
		props: {
			language: window.language ? window.language : 'en-US',
			paymentSystems: (window.paymentSystems ? window.paymentSystems : paymentSystems),
			defaultPaymentSystem: window.language && window.language === 'en-US' ? 'Wise wallet' : 'Банковская карта',
			walletId: window.walletId ? window.walletId : null,
			reservePaymentSystem: window.reservePaymentSystem ? window.reservePaymentSystem : 'PayPal',
			reserveWalletId: window.reserveWalletId ? window.reserveWalletId : null,
			phone: window.phone ? window.phone : null,
			telegram: window.telegram ? window.telegram : null,
			skype: window.skype ? window.skype : null,
			geoIpInfo: window.geoIpInfo ? window.geoIpInfo : {country: null, countryCode: null, city: null},
		}
	});
}

function createTrafficTab() {
	return new Traffic({
		target: document.getElementById('profile-traffic'),
		props: {
			language: window.language ? window.language : 'en-US',
			sourcesList: window.sourcesList ? window.sourcesList : [],
		}
	});
}

function createWithdrawalOrderForm() {
	return new WithdrawOrderForm({
		target: document.getElementById('requestOrder'),
		props: {
			language: window.language ? window.language : 'en-US',
			availableForWithdrawal: window.availableForWithdrawal ? window.availableForWithdrawal : 0,
			currencySign: window.currencySign ? window.currencySign : '$',
			availableWallets: window.availableWallets ? window.availableWallets : [],
			supportEmail: window.supportEmail ? window.supportEmail : 'support@example.com',
		}
	});
}

function createRegistrationForm() {
	return new RegistrationForm({
		target: document.getElementById('dialogRegistration'),
		props: {
			language: window.language ? window.language : 'en-US',
			facebookAuthEnabled: window.facebookAuthEnabled ? window.facebookAuthEnabled : false,
			googleAuthEnabled: window.googleAuthEnabled ? window.googleAuthEnabled : false,
			googleAuthUrl: window.googleAuthUrl ? window.googleAuthUrl : null,
		}
	});
}

function createLoginForm() {
	return new LoginForm({
		target: document.getElementById('dialogEnter'),
		props: {
			language: window.language ? window.language : 'en-US',
			facebookAuthEnabled: window.facebookAuthEnabled ? window.facebookAuthEnabled : false,
			googleAuthEnabled: window.googleAuthEnabled ? window.googleAuthEnabled : false,
			googleAuthUrl: window.googleAuthUrl ? window.googleAuthUrl : null,
		}
	});
}

function createResetPasswordForm() {
	return new ResetPasswordForm({
		target: document.getElementById('dialogResetPassword'),
		props: {
			language: window.language ? window.language : 'en-US',
		}
	});
}

function createSaveNewPasswordForm() {
	return new SaveNewPassword({
		target: document.getElementById('savePasswordForm'),
		props: {
			language: window.language ? window.language : 'en-US',
			resetToken: window.resetToken ? window.resetToken : null,
		}
	});
}

window['createProfileTab'] = createProfileTab;
window['createContactsTab'] = createContactsTab;
window['createTrafficTab'] = createTrafficTab;
window['createWithdrawalOrderForm'] = createWithdrawalOrderForm;
window['createRegistrationForm'] = createRegistrationForm;
window['createLoginForm'] = createLoginForm;
window['createResetPasswordForm'] = createResetPasswordForm;
window['createSaveNewPasswordForm'] = createSaveNewPasswordForm;

export default app;