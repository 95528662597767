<script>
    import {dictionary, locale, _} from "svelte-i18n";
    import {fly, fade } from 'svelte/transition';
    import {apiRequest} from '../libs/Request.svelte';
    import {getCountryPhoneMask} from '../libs/Common.svelte';
    import {onMount} from "svelte";

    export let language;
    export let paymentSystems;
    export let defaultPaymentSystem;
    export let walletId;
    export let reservePaymentSystem;
    export let reserveWalletId;
    export let phone;
    export let telegram;
    export let skype;
    export let geoIpInfo;

    let walletError = '';
    let reserveWalletError = '';
    let phoneError = '';
    let telegramError = '';
    let skypeError = '';
    let isSaveDisabled = false;
    let canSavedWalletId = false;

    onMount(() => {
        if (walletId && walletId.length > 3) {
            canSavedWalletId = true;
        }
    });

    dictionary.set({
        ru: {
            "payouts": {
                "title": "Платежная информация",
                "paymentSystem": "Система для вывода средств",
                "walletId": "Номер кошелька",
                "addReserveWallet": "+ Добавить резервный кошелек",
                "reserveWallet": "Резервный кошелек",
            },
            "contacts": {
                "title": "Контактная информация",
                "phone": "Телефон",
                "phonePlaceholder": "+7 (000) 000 00 00",
                "telegram": "Telegram",
                "skype": "Skype",
            },
            "saveSettings": "Сохранить изменения",
        },
        en: {
            "payouts": {
                "title": "Payment Info",
                "paymentSystem": "The method for withdrawal of funds",
                "walletId": "Wallet number",
                "addReserveWallet": "+ Add a Reserve Wallet",
                "reserveWallet": "Reserve Wallet",
            },
            "contacts": {
                "title": "Contact info",
                "phone": "Phone",
                "phonePlaceholder": "+1 (000) 000 00 00",//getCountryPhoneMask(geoIpInfo.countryCode),
                "telegram": "Telegram",
                "skype": "Skype",
            },
            "saveSettings": "Save changes",
        },
    });

    switch (language) {
        case 'ru-RU':
            locale.set('ru');
            break;
        case 'en-US':
        default:
            locale.set('en');
            break;
    }

    const reserveWallet = $_('payouts.reserveWallet');


    let isSuccessVisible = false;
    let hasError = false;
    async function handleSubmit(e) {
        const response = await apiRequest("post", '/api/save-contacts', JSON.stringify(prepareSettings()), {
            'Content-Type': 'application/json',
        })
        isSuccessVisible = (response.status === 'ok');

        setTimeout(function(){
            isSuccessVisible = false;
        }, 4000);
    }

    function prepareSettings() {
        return {
            defaultPaymentSystem: defaultPaymentSystem,
            walletId: walletId,
            reservePaymentSystem: reserveWalletId ? reservePaymentSystem : null,
            reserveWalletId: reserveWalletId,
            phone: phone,
            telegram: telegram,
            skype: skype,
        };
    }

    let showReserveWallet = reserveWalletId ? true : false;
    function showReserveWalletId() {
        return showReserveWallet;
    }

    function showReserveWalletForm(e) {
        showReserveWallet = true;
    }

</script>

{#if hasError == true}
    <div class="error-alert">{$_('errorOccured')}</div>
{:else}
    {#if isSuccessVisible}
        <div class="error-alert" transition:fade={{duration:150}}>
            {$_('successUpdate')}
        </div>
    {/if}
{/if}

    <form class="profile-settings__form" on:submit|preventDefault={handleSubmit}>
        <fieldset class="profile-settings__fieldset">
            <legend class="profile-settings__fieldset-title">{$_('payouts.title')}</legend>
            <span class="profile-settings__group-title">{$_('payouts.paymentSystem')}</span>
            <div class="profile-settings__group">
                <div class="profile-settings__form-group-select">
                    <label class="select">
                        <span class="wrapper">
                            <span class="arr"></span>
                            <input class="input" type="text" bind:value={defaultPaymentSystem} readonly="" disabled={canSavedWalletId}>
                            <span class="options" style="max-height: 0px;">
                                {#each paymentSystems as item}
                                    <span class="{defaultPaymentSystem === item.title ? 'active' : ''}">
                                        {item.title}
                                    </span>
                                {/each}
                            </span>
                        </span>
                    </label>
                </div>
                <div class="profile-settings__form-group">
                    <label class="{walletError ? 'error' : ''}">
                        <input class="input" type="text" bind:value={walletId} placeholder="{$_('payouts.walletId')}" disabled={canSavedWalletId}>
                    </label>
                    <span class="error-msg">{walletError}</span>
                </div>
            </div>
            <!--{#if !showReserveWallet}
                <button class="btn btn_primary" type="button" on:click|preventDefault={showReserveWalletForm}>
                    {$_('payouts.addReserveWallet')}
                </button>
            {/if}-->
        </fieldset>
        <fieldset class="profile-settings__fieldset {!showReserveWallet ? 'hidden' : ''}">
            <legend class="profile-settings__fieldset-title">{$_('payouts.reserveWallet')}</legend>
            <span class="profile-settings__group-title">{$_('payouts.paymentSystem')}</span>
            <div class="profile-settings__group">
                <div class="profile-settings__form-group-select">
                    <label class="select">
                    <span class="wrapper">
                        <span class="arr"></span>
                        <input class="input" type="text" bind:value={reservePaymentSystem} readonly="">
                        <span class="options" style="max-height: 0px;">
                            {#each paymentSystems as item}
                                <span class="{reservePaymentSystem === item.title ? 'active' : ''}">
                                    {item.title}
                                </span>
                            {/each}
                        </span>
                    </span>
                    </label>
                </div>
                <div class="profile-settings__form-group">
                    <label class="{reserveWalletError ? 'error' : ''}">
                        <input class="input" type="text" bind:value={reserveWalletId} placeholder="{$_('payouts.walletId')}">
                    </label>
                    <span class="error-msg">{reserveWalletError}</span>
                </div>
            </div>
        </fieldset>

        <fieldset class="profile-settings__fieldset">
            <legend class="profile-settings__fieldset-title">{$_('contacts.title')}</legend>
            <div class="profile-settings__form-group">
                <label class="{phoneError ? 'error' : ''}">
                    <span class="profile-settings__label">{$_('contacts.phone')}</span>
                    <input class="input" type="text" bind:value={phone} placeholder="{$_('contacts.phonePlaceholder')}">
                </label>
                <span class="error-msg">{phoneError}</span>
            </div>
            <div class="profile-settings__form-group">
                <label class="{telegramError ? 'error' : ''}">
                    <span class="profile-settings__label">{$_('contacts.telegram')}</span>
                    <input class="input" type="text" bind:value={telegram} placeholder="@username">
                </label>
                <span class="error-msg">{telegramError}</span>
            </div>
            <div class="profile-settings__form-group">
                <label class="{skypeError ? 'error' : ''}">
                    <span class="profile-settings__label">{$_('contacts.skype')}</span>
                    <input class="input" type="text" bind:value={skype} placeholder="">
                </label>
                <span class="error-msg">{skypeError}</span>
            </div>
        </fieldset>
        <div class="profile-settings__button-group">
            <button class="btn btn_primary" type="submit" disabled:isSaveDisabled>
                {$_('saveSettings')}
            </button>
        </div>
    </form>
