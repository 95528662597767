<script context="module">
    import axios from "axios";

    export const axiosAPI = axios.create();
    export const apiRequest = async (method, url, request, headers = {}) => {
        return axiosAPI({
        method,
        url,
        data: request,
        headers
    }).then(res => {
        return Promise.resolve(res.data);
    })
        .catch(err => {
        return Promise.reject(err);
    });
    };
    export const post = async (url, request) => await apiRequest("post", url, (new URLSearchParams(request)).toString(), {
        'Content-Type': 'application/x-www-form-urlencoded',
    });

    export const get = async (url) => await apiRequest("get", url, null, { });
</script>