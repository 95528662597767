<script>
    import {dictionary, locale, _} from "svelte-i18n";
    import {fly, fade } from 'svelte/transition';
    import { onMount } from 'svelte';
    import {apiRequest} from '../libs/Request.svelte';

    export let language;
    export let availableForWithdrawal;
    export let currencySign;
    export let availableWallets;
    export let supportEmail;

    let withdrawalAmount;
    let wallet;

    function onWalletInputChange(event) {
        wallet = event.target.value;
    }

    onMount(async () => {
        // TODO: subsribe
        document.getElementById('wallet-id').addEventListener('optionEventClick', onWalletInputChange, false);
        return () => {
            // TODO: unsubsribe
            document.getElementById('wallet-id').removeEventListener('optionEventClick', onWalletInputChange, false);
        }
    });

    let withdrawalAmountError;

    dictionary.set({
        ru: {
            'payoutOrder': 'Заказать вывод средств',
            'payoutDescription': 'Оставьте заявку на вывод средств. Обработка заявки может занять некоторое время...',
            'availableForWithdrawal': 'Доступно для вывода',
            'availableForWithdrawalDescription': 'доступная сумма для заказа выплаты',
            'enterWithdrawalAmount': 'Укажите сумму вывода',
            'gotoWithdrawalSettings': 'Перейти в настройки вывода',
            'withdrawalSettings': 'Настройки вывода',
            'orderWithdrawal': 'Заказать вывод средств',
            'contactUsIntro': 'По всем вопросам обращайтесь',
            'withdrawalWallet': 'Кошелек для вывода',
            'selectTargetWallet': 'Выберите куда вывести ср-ва',
        },
        en: {
            'payoutOrder': 'Withdrawal request',
            'payoutDescription': 'Submit a request to withdraw funds. The processing of the request may take some time',
            'availableForWithdrawal': 'Available to withdraw',
            'availableForWithdrawalDescription': 'Available amount for payment order',
            'enterWithdrawalAmount': 'Enter the withdrawal amount',
            'gotoWithdrawalSettings': 'Go to Output Settings',
            'withdrawalSettings': 'Output settings',
            'orderWithdrawal': 'Request Withdrawal',
            'contactUsIntro': 'For any questions, please contact',
            'withdrawalWallet': 'Withdrawal wallet',
            'selectTargetWallet': 'Select where to withdraw funds',
        },
    });

    switch (language) {
        case 'ru-RU':
            locale.set('ru');
            break;
        case 'en-US':
        default:
            locale.set('en');
            break;
    }

    function hasWallets() {
        return (availableWallets.length > 0);
    }

    function prepareOrder() {
        return {
            withdrawalAmount: withdrawalAmount,
            wallet: wallet,
        }
    }

    let isSuccessVisible = false;
    async function handleSubmit(e) {

        const response = await apiRequest("post", '/api/create-payout-order', JSON.stringify(prepareOrder()), {
            'Content-Type': 'application/json',
        })
        isSuccessVisible = (response.status === 'ok');

        if (isSuccessVisible) {
            switchDialog('requestSent', 'requestSentOrder');
        }

        // setTimeout(function(){
        //     isSuccessVisible = false;
        // }, 4000);
    }

</script>

<div class="modal__content">
    <button class="modal__close" type="button" data-toggle="modal" data-direction="hide" data-target-id="requestSentOrder"></button>
    <div class="modal__header">
        <h4 class="modal__title">
            {$_('payoutOrder')}
        </h4>
        <p class="modal__subtitle">
            {$_('payoutDescription')}
        </p>
    </div>
    <div class="modal__body">
        <div class="balance__card balance__card_dark">
            <h4>{$_('availableForWithdrawal')}</h4>
            <p>{availableForWithdrawal} {currencySign}</p>
            <span class="informer">
                        <span class="informer__body">
                            <span class="informer__title">{$_('availableForWithdrawal')}</span>
                            <span class="informer__desc">{$_('availableForWithdrawalDescription')}</span>
                        </span>
                    </span>
        </div>
        <form class="modal__form" on:submit|preventDefault={handleSubmit}>
            <div class="modal__form-group">
                <span class="profile-settings__label">
                    {$_('enterWithdrawalAmount')}
                </span>
                <label class="{withdrawalAmountError ? 'error' : ''}">
                    <input class="input" type="text" bind:value={withdrawalAmount} placeholder={$_('enterWithdrawalAmount')} />
                </label>
                <span class="modal__err">{withdrawalAmountError}</span>
            </div>

            <div class="modal__form-group">
                <span class="profile-settings__label">
                    {$_('withdrawalWallet')}
                </span>
                {#if hasWallets()}
                <label class="select">
                            <span class="wrapper">
                                <span class="arr"></span>
                                <input class="input" id="wallet-id" type="text" bind:value={wallet} placeholder="{$_('selectTargetWallet')}" readonly="" />
                                <span class="options" style="max-height: 0px;">
                                    {#each availableWallets as wallet}
                                        <span>{wallet}</span>
                                    {/each}

                                    <span class="link">
                                        <a href="/dashboard/profile?tab=contacts">
                                            {$_('gotoWithdrawalSettings')}
                                        </a>
                                    </span>
                                </span>
                            </span>
                </label>
                {/if}
                {#if !hasWallets()}
                    <a class="btn btn_primary w-100 "
                       href="/dashboard/profile?tab=contacts"
                    >{$_('withdrawalSettings')}</a>
                {/if}
            </div>

            <div class="modal__form-group modal__form-group_btn-vertical">
                <div class="modal__form-group modal__form-group_btn-vertical">
                    <button class="btn btn_lite"
                            role="dialog"
                            disabled={!hasWallets() || (Number(availableForWithdrawal) === 0)}
                    >{$_('orderWithdrawal')}</button>
                </div>
            </div>

        </form>
    </div>
    <div class="modal__footer">
        <div class="modal__contacts">
            {$_('contactUsIntro')} <a class="link" href={`mailto:${supportEmail}`}>{supportEmail}</a>
        </div>
    </div>
</div>
