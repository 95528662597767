<script>
    import {dictionary, locale, _} from "svelte-i18n";
    import {fly, fade } from 'svelte/transition';
    import {apiRequest} from '../libs/Request.svelte';

    export let language;
    export let resetToken;
    let password;
    let confirmPassword;

    dictionary.set({
        ru: {
            'title': 'Смена пароля',
            'passwordsDoNotMatch': 'Пароли не совпадают',
            'passwordUpdated': 'Пароль успешно обновлен',
        },
        en: {
            'title': 'Change password',
            'passwordsDoNotMatch': 'Passwords don\'t match',
            'passwordsCanNotBeEmpty': 'Password can\'t be empty',
            'passwordUpdated': 'Password successfully updated',
            'passwordPlaceholder': 'Password',
            'confirmPasswordPlaceholder': 'Confirm password',
            'submitBtnLabel': 'Save',
        },
    });

    switch (language) {
        case 'ru-RU':
            locale.set('ru');
            break;
        case 'en-US':
        default:
            locale.set('en');
            break;
    }

    let errMessage = '';

    function checkNewPassword() {
        let result = (password === confirmPassword);
        if (!result) {
            errMessage = $_('passwordsDoNotMatch')
            return false;
        }

        result = (password !== '');
        if (!result) {
            errMessage = $_('passwordsCanNotBeEmpty')
            return false;
        }

        return true;
    }

    function resetErrors() {
        errMessage = '';
    }

    function prepareSettings() {
        return {
            'newPassword': password,
            'resetToken': resetToken,
        };
    }

    let isSuccessVisible = false;
    async function handleSubmit(e) {
        resetErrors();
        if (!checkNewPassword()) {
            return;
        }

        const response = await apiRequest("post", '/api/save-password', JSON.stringify(prepareSettings()), {
            'Content-Type': 'application/json',
        })
        isSuccessVisible = (response.status === 'ok');
        isSuccessVisible = true;

        errMessage = response.error ? response.error : '';

        setTimeout(function(){
            isSuccessVisible = false;
        }, 4000);
    }

</script>

    <div class="error-alert {errMessage ? '' : 'hidden'}">{errMessage}</div>
    <div class="error-alert {isSuccessVisible ? '' : 'hidden'}" transition:fade={{duration:150}}>
        {$_('passwordUpdated')}
    </div>

    <form class="profile-settings__form" on:submit|preventDefault={handleSubmit}>
        <fieldset class="profile-settings__fieldset">
            <legend class="profile-settings__fieldset-title hidden">{$_('title')}</legend>
            <div class="profile-settings__form-group">
                <label class="label_password {errMessage ? 'error' : 'valid'}">
                    <input class="input" type="password" placeholder="{$_('passwordPlaceholder')}" bind:value={password}>
                </label>
            </div>
            <div class="profile-settings__form-group">
                <label class="label_password {errMessage ? 'error' : 'valid'}">
                    <input class="input" type="password" placeholder="{$_('confirmPasswordPlaceholder')}" bind:value={confirmPassword}>
                </label>
            </div>
        </fieldset>
        <div class="profile-settings__button-group">
            <button class="btn btn_primary" type="submit">{$_('submitBtnLabel')}</button>
        </div>
    </form>
